<template>
  <div>
    <div class="card">
      <div class="card-body">
        <b-form class="row" v-if="show">

          <div class="col-md-8">
            <h2 class="title-block">Danh sách userId trong white list</h2>
            <div class="">

              <b-form-group
                  id="input-dk-2"
                  label="Nhập danh sách userID"
                  label-for="ms-desc"
                  description="Nhập danh sách dạng Vd: 123,4232,4444,2212,..."
              >
                <b-form-textarea
                    id="ms-desc"
                    v-model="form.userIds"
                    placeholder="Nhập userIds "
                    min="0"
                    rows="4"
                    @change="removeSpace()"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-8">
            <div class="">

              <b-form-group
                  id="input-dk-2"
                  label="Trạng thái"
                  label-for="ms-desc"
              >

                <div class="form-inline">
                  <b-form-radio :value="1" v-model="form.status">Áp dụng</b-form-radio>
                  <b-form-radio :value="0" style="margin-left: 10px" v-model="form.status ">Không áp dụng</b-form-radio>
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-12">
            <b-button @click="saveCombo()" variant="primary">Cập nhập</b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
const CampaignRepository = RepositoryFactory.get("Campaign");

export default {
  name: "FormMission",
  mixins: [Common],
  components: {
  },
  data() {
    return {
      form: {
        status: 0,
        userIds: '',
      },
      show: true,
      is_edit: false,
    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí white list nhiệm vụ ", route: "/campaign/setting" },
    ]);
  },
  methods: {
    removeSpace(){
      this.form.userIds = this.form.userIds.split(/\s+/).join('')
    },

    saveCombo(){
      let params = this.form;
      CampaignRepository.storeSetTings(params)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
              return false;
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: response.data.message,
              });

            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
            return false;
          });
    },
    getSetTings(){
      CampaignRepository.getSetTings()
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (0=== response.data.error_code) {
             let body = response.data.data;
             this.form.userIds = JSON.parse(body.value).userIds;
             this.form.status = body.status;

            }})
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
            return false;
          });

    },
  },
  created() {
    this.getSetTings();

  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
.datetime-picker div input{
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
